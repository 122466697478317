* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 17px;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.info,
.project-info,
.bilser {
  height: auto;
  text-align: center;
  padding: 50px 25px;
  color: #181819;
}
.info p,
.project-info p {
  padding-top: 10px;
  color: #696969;
}

.header {
  padding: 15px 0px;
}

.card-group {
  padding: 25px 50px;
}
.card-image {
  height: 220px;
}
.card-link {
  text-decoration: none;
}

.main-footer {
  background-color: #f8f9fa;
  padding: 30px;
  color: #696969;
}

.main-footer li {
  margin-top: 10px;
}
.main-footer h6 {
  color: #181819;
}
.main-footer a {
  color: #696969;
  text-decoration: none;
}
.main-footer a:hover {
  color: #4a4a4b;
}
.footer-icon {
  padding-right: 7px;
}
.card-link {
  color: #696969 !important ;
}
.card-link:hover {
  color: #4a4a4b !important;
}
.card-title {
  color: #181819;
}
.projects-card {
  margin: 10px;
}
.header-title {
  height: 125px;
  background-color: #181819;
  color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-title span {
  font-size: 30px;
}
.main-contact {
  margin: 60px 0px;
  margin-right: 18px;
}
.main-contact span {
  font-size: 25px;
  color: #696969;
}
.main-contact li {
  margin: 15px 0px;
}
.contact-icon {
  padding-right: 15px;
}
.projects-div {
  margin: 20px 0px;
}
.projects-tab {
  padding-bottom: 50px;
}
.projects-tabs {
  padding-top: 45px;
}
.kurumsal-info p {
  font-size: 20px;
  color: #696969;
  margin-right: 25px;
}
.kurumsal-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-kurumsal {
  margin: 60px 30px;
}
.kurumsal-image img {
  height: 600px;
  width: 550px;
}
.main-contact-map li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}
@media screen and (max-width: 500px) {
  .footer-map {
    width: 330px;
    height: 280px;
  }
}
@media screen and (max-width: 570px) and (min-width: 501px) {
  .footer-map {
    width: 450px;
    height: 280px;
  }
}
.kurumsal-images {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kurumsal-images img {
  height: 220px;
  width: 320px;
  margin: 20px;
}

@media screen and (max-width: 600px) {
  .kurumsal-images {
    display: flex;
    flex-direction: column;
  }
}
.bilser img {
  height: 140px;
  width: 160px;
  margin: 28px;
}
.bilser-logo {
  height: 80px;
  width: 120px;
}
.contact-image {
  background-color: #181819;
  color: #f8f9fa;
  height: 580px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 90px;
}
.contact-image button {
  color: #f8f9fa;
  height: 40px;
  width: 100px;
  font-size: 20px;
  margin-top: 30px;
  border: 1px solid #f8f9fa;
  text-align: center;
  border-radius: 2px;
}
.contact-image span {
  font-size: 15px;
  margin-bottom: 10px;
}
.contact-image div {
  font-size: 50px;
  margin-bottom: 30px;
}
.social-media {
  display: flex;
  flex-direction: row;
}
.project-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-top: 38px;
  margin-bottom: 38px;
}

.project-icon span {
  margin-top: 15px;
  text-align: center;
}

.project-details {
  border: 5px solid white;
  background-color: #181819;
  color: #f8f9fa;
}
